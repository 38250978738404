import React, { useEffect, useContext } from 'react'
import {
  HomeOutlined,
  FolderOpenOutlined,
  ListAltOutlined,
  HandshakeOutlined,
  MonetizationOnOutlined,
  SupervisorAccountOutlined,
  CampaignOutlined,
  SettingsOutlined,
  TextsmsOutlined,
  HomeRepairService,
  Logout
} from '@mui/icons-material'
import NavItem from './NavItem'
import './nav.scss'
import styles from './Header.module.scss'
import RecoExpiry from './RecoExpiry'
import { UserContext } from 'context'
import moment from 'moment'
import gql from 'graphql-tag'
import { useQuery, useSubscription } from '@apollo/client'
import { createTheme, useMediaQuery } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useNavigate } from 'react-router-dom'

const NavItems = ({ mobile = false }) => {
  const { user } = useContext(UserContext)
  const { data: queryData } = useQuery(GET_UNREAD_ANNOUNCEMENT_COUNT)
  const initialUnreadAnnouncementCount = queryData?.getAnnouncementCount ?? 0
  const { data: subscriptionData } = useSubscription(UNREAD_ANNOUNCEMENT_COUNT_SUBSCRIPTION)
  const updatedUnreadAnnouncementCount = subscriptionData?.unreadAnnouncementCount?.unreadCount
  const isMobile = useMediaQuery('(max-width:600px)')
  const navigate = useNavigate()

  const openWhatsApp = () => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    if (isMobileDevice) {
      window.location.href = `${process.env.REACT_APP_CHAT_WHATSAPP_LINK}`
      navigate('/')
    } else {
      window.open(`${process.env.REACT_APP_CHAT_WEB_WHATSAPP_LINK}`, '_blank')
      navigate('/')
    }
  }

  // Load the Fillout script on component mount
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://server.fillout.com/embed/v1/'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <nav>
      <div id="menu-items">
        <ul style={{ marginTop: mobile ? 0 : 24 }}>
          {isMobile && user.role === 'agent' && <RecoExpiry expiryDate={user.reco_expiry} isNav={true} />}
          <NavItem icon={<HomeOutlined />} to="/" title="Home" />
          <NavItem
            to="/announcements"
            title="Announcements"
            icon={
              <div className={styles.announcementWrapper}>
                <CampaignOutlined />
                {(updatedUnreadAnnouncementCount ?? initialUnreadAnnouncementCount) > 0 && (
                  <span className={styles.announcementCount}>
                    {updatedUnreadAnnouncementCount ?? initialUnreadAnnouncementCount}
                  </span>
                )}
              </div>
            }
          />
          {/* <NavItem icon={<TextsmsOutlined />} to="/chat" title="Chat" />
          <NavItem icon={<Comment />} to="/forum" title="Forum" /> */}
          <NavItem icon={<FolderOpenOutlined />} to="/library" title="Library" />
          <NavItem icon={<ListAltOutlined />} to="/listings" title="Listings" />
          <NavItem icon={<HandshakeOutlined />} to="/deals" title="Deals" />
          <NavItem icon={<MonetizationOnOutlined />} to="/revenue_share" title="Revenue Share" />
          {user.role === 'super_admin' && <NavItem icon={<SupervisorAccountOutlined />} to="/admin" title="Admin" />}
          {/* <NavItem icon={<HomeRepairService />} to="/toolbox" title="Toolbox" /> */}
          <NavItem icon={<WhatsAppIcon />} to="/whatsapp" title="Live Chat" onClick={openWhatsApp} />
          {user.role === 'super_admin' && <NavItem icon={<SettingsOutlined />} to="/settings" title="Settings" />}
        </ul>

        <ul style={{ marginBottom: mobile ? 0 : 24 }}>
          {user.godmode ? (
            <NavItem icon={<Logout />} type="exit_god_mode" title="Exit Super mode" />
          ) : (
            <NavItem icon={<Logout />} type="logout" title="Logout" />
          )}
          {user.email && (
            <div
              key={user.email}
              data-fillout-id="ctXFATzUDaus"
              data-fillout-embed-type="slider"
              data-fillout-button-text="Invite Agent"
              data-fillout-button-color="#0f0f0f"
              data-fillout-button-float="bottom-right"
              data-fillout-slider-direction="right"
              data-fillout-inherit-parameters
              data-fillout-domain="forms.growthspan.ca"
              data-fillout-popup-size="small"
              data-email={user.email}></div>
          )}
        </ul>
      </div>
    </nav>
  )
}

const GET_UNREAD_ANNOUNCEMENT_COUNT = gql`
  query GetUnreadAnnouncementCount {
    getAnnouncementCount(id: "unread")
  }
`
const UNREAD_ANNOUNCEMENT_COUNT_SUBSCRIPTION = gql`
  subscription UnreadAnnouncementCount {
    unreadAnnouncementCount {
      unreadCount
    }
  }
`

export default NavItems
